
























.summary-list-item {
  &:not(:last-child) {
    margin-bottom: 1em;
  }

  @media (min-width: 40rem) {
    display: grid;
    gap: 1em;
    grid-template-columns: 10em 1fr;
  }
}

.title {
  font-weight: 700;
}
